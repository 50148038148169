<template>
    <div>
        <v-app class="fullLayout gradientBackground" >
            <div>
                <TopBar ></TopBar>
            </div>
            <div >
                <v-main class="body">
                    <div class="routerView">
                        <router-view></router-view>
                    </div>
                </v-main>
            </div>
        </v-app>
    </div>
</template>
<script>
import TopBar from  '../navigationComponents/appointmentTopBar.vue'
export default {
    name: 'appointmentRoomLayout',
    components: {
        TopBar
    }
}
</script>
<style scoped>
.fullLayout {
    display: flex; flex-direction: column; justify-content: center;
}
.home {
  width: 100%;
  height: 850px;
  background: linear-gradient(135deg, #FFDDDE -0.78%, #CFECFF 99.22%) !important;
  background-color: rebeccapurple !important;
}
.layout{
    top: 0em;
    width: 100%;
    height: 60px;
}
.routerView{
    display: flex;
    justify-content: center;
    height: calc(100vh - 60px) !important;
    overflow-y: auto !important;
    overflow-x: hidden;
}
.body{
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    
    margin-top: 70px;
}
.gradientBackground {
    background: linear-gradient(135deg, #FFDDDE -0.78%, #CFECFF 99.22%) !important;
}
</style>